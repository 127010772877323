var ui_animation = {

  init: function(){

    if(!isMobile) {

      // slideUp animation
      appear({
        elements: function elements(){

          return document.querySelectorAll('.fadeUp');

        },
        appear: function appear(slideUp){
          
          var elem = slideUp.children;

          setTimeout(function(){
            TweenMax.set(elem, {opacity: 1});
            TweenMax.set(slideUp, {opacity: 1});
            TweenMax.staggerFrom(elem, 0.6, {opacity: 0, y: 50, delay: 0.2, ease: Quart.easeOut }, 0.2);
          }, 100);

        },
        
        bounds: 200,
        reappear: false
      });

      // slideDown animation
      appear({
        elements: function elements(){

          return document.querySelectorAll('.slideDown');
        },
        appear: function appear(slideDown){
          
          setTimeout(() => {
            TweenMax.set(slideDown, {opacity: 1 });
            TweenMax.from(slideDown, 0.6, {y: '-100%', ease: Quart.easeOut });
          }, 2000);

        },
        
        bounds: 200,
        reappear: false
      });

      // fade animation
      appear({
        elements: function elements(){

          return document.getElementsByClassName('fadeIn');
        },
        appear: function appear(fadeIn){
          
          setTimeout(() => {
            TweenMax.to(fadeIn, 1.5, {opacity: 1, ease: Quart.easeOut});
          }, 100);

        },
        
        bounds: 200,
        reappear: false
      });

      // coverDown animation
      appear({
        elements: function elements(){

          return document.getElementsByClassName('coverDown');
        },
        appear: function appear(coverDown){
          
          coverDown.classList.add('animated');

        },
        
        bounds: 200,
        reappear: false
      });

      // fade animation
      appear({
        elements: function elements(){

          return document.getElementsByClassName('desktop-process');
        },
        appear: function appear(){

          var anim_1 = document.querySelector('.anim-1');
          var anim_2 = document.querySelector('.anim-2');
          var anim_3 = document.querySelector('.anim-3');
          var anim_4 = document.querySelector('.anim-4');
          var anim_5 = document.querySelector('.anim-5');
          var anim_6 = document.querySelector('.anim-6');
          var anim_7 = document.querySelector('.anim-7');
          var anim_8 = document.querySelector('.anim-8');
          var anim_9 = document.querySelector('.anim-9');
          var anim_10 = document.querySelector('.anim-10');
          
          setTimeout(() => {
            TweenMax.to(anim_1, 2, {opacity: 1, ease: Quart.easeOut});
          }, 500);
          setTimeout(() => {
            TweenMax.to(anim_2, 2, {opacity: 1, ease: Quart.easeOut});
          }, 1000);
          setTimeout(() => {
            TweenMax.to(anim_3, 2, {opacity: 1, ease: Quart.easeOut});
          }, 1500);
          setTimeout(() => {
            TweenMax.to(anim_4, 2, {opacity: 1, ease: Quart.easeOut});
          }, 2000);
          setTimeout(() => {
            TweenMax.to(anim_5, 2, {opacity: 1, ease: Quart.easeOut});
          }, 2500);
          setTimeout(() => {
            TweenMax.to(anim_6, 2, {opacity: 1, ease: Quart.easeOut});
          }, 3000);
          setTimeout(() => {
            TweenMax.to(anim_7, 2, {opacity: 1, ease: Quart.easeOut});
          }, 3500);
          setTimeout(() => {
            TweenMax.to(anim_8, 2, {opacity: 1, ease: Quart.easeOut});
          }, 4000);
          setTimeout(() => {
            TweenMax.to(anim_9, 2, {opacity: 1, ease: Quart.easeOut});
          }, 4500);
          setTimeout(() => {
            TweenMax.to(anim_10, 2, {opacity: 1, ease: Quart.easeOut});
          }, 5000);

        },
        
        bounds: 200,
        reappear: false
      });
  
    }

  }

}

ui_animation.init();



