var file_upload = (function(document) {

	var evt = [

		// accordion - function to control an accordion menu

		function(document) {

			var toggle = document.querySelectorAll('.file-upload');

			toggle.forEach(function(file) {

        var fileChosen = file.parentNode.querySelector('.file-chosen');

				file.addEventListener('change', function (event) {

					fileChosen.textContent = this.files[0].name

				}, false);
			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

file_upload.init();