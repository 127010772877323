var ui_slider_thumb = (function(document) {

	var evt = [

		function(document) {

			var swiperGalleries = document.querySelectorAll('.gallery-slider');

			swiperGalleries.forEach(function(swiperGallery){

				var swiperTops = swiperGallery.querySelector('.swiper-top');
				var swiperBottoms = swiperGallery.querySelector('.swiper-thumb');

				var swiperTop = new Swiper(swiperTops, {
					spaceBetween: 10,
					slidesPerView: 1,
					loop: true,
					loopedSlides: 4
				});
	
				var swiperThumb = new Swiper(swiperBottoms, {
					spaceBetween: 10,
					slidesPerView: 2,
					loop: true,
					centeredSlides: true,
					slideToClickedSlide: true,
					touchRatio: 0.2,
					loopedSlides: 4,
					breakpoints: {
						640: {
							slidesPerView: 2,
						},
						768: {
							slidesPerView: 2,
	
						},
						1024: {
							slidesPerView: 3,
						},
					},
				});
	
				swiperTop.controller.control = swiperThumb;
				swiperThumb.controller.control = swiperTop;

			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

document.addEventListener('DOMContentLoaded', function(event) {
  ui_slider_thumb.init();
});