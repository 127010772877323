/* JavaScript File                                                  */
/* ui-tabs.js                                                  		*/
/* Modified January 22, 2019                                        */


var ui_tabs = (function(document) {

	var evt = [

		// tabs - function to control a tabbed interface

		function(document, window, args) {

			var tabs = document.querySelectorAll('[data-toggle=tab]');

			tabs.forEach(function(trigger) {

				trigger.addEventListener('click', function (event) {
					event.preventDefault();

					var target = document.querySelector(trigger.getAttribute('href'));
					var ss = trigger.closest('.nav-tabs');

					
					var aa = document.querySelector('.tab-pane.active');
					var bb = document.querySelector('.tab-pane.show');

					ss.querySelector('a.active').classList.remove('active');

					aa.classList.remove('active');
					bb.classList.remove('show');


					trigger.classList.add('active');
					target.classList.add('show');
					target.classList.add('active');

				}, false);

			});
		}

	],
	initAll = function(args) {

		initEvt(document, window, args);
	},
	initEvt = function(document, window, args) {

		evt.forEach(function(e) {
			
			e(document, window, args);
		});
	};
	
	return { init: initAll };

})(document, window);

ui_tabs.init();