var ui_form = (function(document) {

	var evt = [

		// accordion - function to control an accordion menu

		function(document) {

			var toggle = document.querySelectorAll('.form-select');

			toggle.forEach(function(input) {


				input.addEventListener('change', function (event) {

					var index = input.selectedIndex;

					if(input.value == 'If Email, Email Address') {

						document.querySelector('.input-email').classList.add('active');
						document.querySelector('.input-phone').classList.remove('active');
					
					} else if(input.value == 'If Call / WhatsApp, Telephone Number') {

						document.querySelector('.input-email').classList.remove('active');
						document.querySelector('.input-phone').classList.add('active');
					
					} else {

						document.querySelector('.input-email').classList.remove('active');
						document.querySelector('.input-phone').classList.remove('active');
					}

				}, false);
			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

ui_form.init();